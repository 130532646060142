var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pt-xl relative-position"},[_c('h3',{staticClass:"text-h6"},[_vm._v(" SMALL SCALE ENTERPRISE LOAN GUARANTEE SCHEME PROPOSAL AND GUARANTEE "),_c('br'),_vm._v(" Form T for "),_c('span',{staticClass:"text-weight-bold"},[_vm._v(_vm._s(_vm.formT.nameOfFinancier))])]),_c('h3',{staticClass:"text-h5 text-weight-regular"},[_c('span',{staticClass:"text-subtitle1 text-weight-bold block"},[_vm._v(" Name Of Portfolio: "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.formT.name))])]),_c('span',{staticClass:"text-subtitle1 text-weight-bold block"},[_vm._v(" Address: "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.formT.addressOfFinancier))])]),_c('span',{staticClass:"text-subtitle1 text-weight-bold block"},[_vm._v(" Total Loans: "),_c('span',{staticClass:"text-weight-regular"},[_vm._v(_vm._s(_vm.formatNumber(_vm.total)))])])]),_c('div',[_c('q-table',{attrs:{"title":"Borrower Details","filter":_vm.filter,"data":_vm.formT.applications,"columns":_vm.columns,"row-key":"id","binary-state-sort":"","pagination":_vm.pagination,"loading":_vm.loading},on:{"row-click":function (evt, row, index) { return _vm.$router.push({
            name: 'ProfilesApplication',
            query: { id: row.id },
          }); },"update:pagination":function($event){_vm.pagination=$event}}})],1),_c('q-dialog',{attrs:{"full-height":"","full-width":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('comments',{attrs:{"id":_vm.$route.query.id,"type":_vm.commentType}})],1),_c('q-page-sticky',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"position":"bottom-right","offset":[20, 6]}},[_c('q-btn-group',{staticClass:"q-mt-sm",attrs:{"rounded":""}},[_c('q-btn',{attrs:{"rounded":"","icon":"arrow_back","color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('q-tooltip',{attrs:{"transition-show":"rotate","transition-hide":"rotate"}},[_vm._v(" Go back to previous view ")])],1),(_vm.formT.status === _vm.approvedStatus)?_c('q-btn',{attrs:{"rounded":"","color":"primary","icon":"rate_reviews"},on:{"click":function () { return [(_vm.showDialog = true)]; }}},[_c('q-tooltip',{attrs:{"transition-show":"rotate","transition-hide":"rotate"}},[_vm._v(" Comments ")])],1):_vm._e(),(_vm.showApprove)?[_c('q-btn',{attrs:{"rounded":"","color":"primary","icon":"block"},on:{"click":function () { return _vm.$router.push({
                name: 'RejectPortfolio',
                query: {
                  id: _vm.formT.id,
                  name: _vm.formT.name,
                  nameOfFinancier: _vm.formT.nameOfFinancier,
                  reference: _vm.formT.reference,
                  status: _vm.formT.status,
                },
              }); }}},[_c('q-tooltip',{attrs:{"transition-show":"rotate","transition-hide":"rotate"}},[_vm._v(" Reject ")])],1),(_vm.formT.status !== _vm.approvedStatus)?_c('q-btn',{attrs:{"rounded":"","color":"primary","icon":"done_all"},on:{"click":function($event){return _vm.approve()}}},[_c('q-tooltip',{attrs:{"transition-show":"rotate","transition-hide":"rotate"}},[_vm._v(" "+_vm._s(_vm.ApproveLabel)+" ")])],1):_vm._e()]:_c('q-btn',{attrs:{"rounded":"","color":"primary","icon":"visibility"},on:{"click":function () { return _vm.$router.push({
              name: 'ViewDocuments',
              query: {
                id: _vm.approvalLetter.documentID,
                author: ("By " + (_vm.approvalLetter.author.fullName)),
                fileName: _vm.approvalLetter.originalFileName,
                reference: 'Approval letter',
              },
            }); }}},[_c('q-tooltip',{attrs:{"transition-show":"rotate","transition-hide":"rotate"}},[_vm._v(" View approval letter ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }