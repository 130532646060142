































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ApplicationStatus } from "@/models/businessApplication";
import DrawdownComponent from "@/components/Drawdown.vue";
import agent from "@/helpers/agent";
import Comments from "@/components/Comments.vue";
import FormT, { ApplicationDetails } from "@/models/formT";
import { CommentType } from "@/models/comment";
import { namespace } from "vuex-class";
import Notifier from "@/helpers/notifier";
import {
  BusinessDocument,
  BusinessDocumentType,
  DocumentProfile,
  DocumentStatus,
} from "@/models/businessDocument";
import numberWithCommas from "@/helpers/numberWithCommas";
import { AppUser } from "@/models/user";
import * as _ from "lodash";

const app = namespace("app");

@Component({ components: { DrawdownComponent, Comments } })
export default class FormRView extends Vue {
  @app.Getter role!: string;
  @app.Getter user!: AppUser;

  formT: FormT = {
    id: "",
    name: "",
    nameOfFinancier: "",
    addressOfFinancier: "",
    reference: "",
    branch: "",
    status: ApplicationStatus.Pending,
    applications: [],
  };
  approvalLetter: BusinessDocument = {
    documentID: "",
    filePath: "",
    originalFileName: "",
    documentProfile: DocumentProfile.Approval,
    type: BusinessDocumentType.ApprovalLetter,
    comment: "",
    profileOrder: 8,
    isValid: false,
    isPass: false,
    isReviewed: false,
    isAlternateDocRequest: false,
    isAlternateDocReturned: false,
    status: DocumentStatus.Pending,
    profile: DocumentProfile.Approval,
    authorID: "",
    businessApplicationID: "",
    businessApplication: "",

  };
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  loading = true;
  showDialog = false;
  commentType = CommentType.Portfolio;
  filter = "";
  columns = [
    {
      name: "Reference",
      required: true,
      label: "Reference",
      align: "left",
      field: (row: ApplicationDetails) => row.reference,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: ApplicationDetails) => row.nameAndAddress,
      sortable: true,
    },
    {
      name: "percentageGuarantee",
      required: true,
      label: "% Guarantee",
      align: "left",
      field: (row: ApplicationDetails) => row.percentageGuarantee,
      format: (val: string) =>
        val === null ? "Form R not yet submitted" : `${val} %`,
      sortable: false,
    },
    {
      name: "maximumFacilityRequired",
      required: true,
      label: "MaximumFacility Required",
      align: "left",
      field: (row: ApplicationDetails) => row.maximumFacilityRequired,
      format: (val: number) =>
        val === null
          ? "Form R not yet submitted"
          : `E ${numberWithCommas(val.toFixed(2))}`,
      sortable: true,
    },
    {
      name: "FacilityRequired",
      required: true,
      label: "Guarantee Required",
      align: "left",
      field: (row: ApplicationDetails) =>
        row.maximumFacilityRequired && row.percentageGuarantee
          ? row.maximumFacilityRequired * (row.percentageGuarantee / 100)
          : null,
      format: (val: number) =>
        val === null
          ? "Form R not yet submitted"
          : `E ${numberWithCommas(val.toFixed(2))}`,
      sortable: true,
    },
  ];
  get total() {
    return `E ${this.formT.applications
      .reduce((acc, curr) => acc + curr.maximumFacilityRequired, 0)
      .toFixed(2)}`;
  }
  get showApprove() {
    if (this.formT.status === ApplicationStatus.Rejected) return false;
    if (this.user.role === "User") {
      return this.formT.status === ApplicationStatus.Pending;
    } else if (this.user.role === "Manager") {
      return this.formT.status === ApplicationStatus.PendingReview;
    } else if (this.user.role === "Reviewer") {
      return this.formT.status === ApplicationStatus.SubmittedToCentralBank;
    } else {
      return this.formT.status === ApplicationStatus.Reviewed;
    }
  }
  get ApproveLabel() {
    switch (this.role) {
      case "User":
        return "Submit to manager";
      case "Manager":
        return "Submit to central bank";
      case "Reviewer":
        return "Submit for approval";
      default:
        return "Approve";
    }
  }
  status(status: string) {
    return _.startCase(ApplicationStatus[Number(status)]);
  }
  formatNumber(x: string) {
    return numberWithCommas(x);
  }

  approve() {
    switch (this.role) {
      case "User":
        agent.PortFolios.submitToManager(this.$route.query.id as string)
          .then(() =>
            Notifier.showPositive("Portfolio submitted to manager for approval")
          )
          .then(() => (this.formT.status = ApplicationStatus.PendingReview));
        break;
      case "Manager":
        agent.PortFolios.submitForReview(this.$route.query.id as string)
          .then(() =>
            Notifier.showPositive("Portfolio submitted to Central Bank")
          )
          .then(
            () => (this.formT.status = ApplicationStatus.SubmittedToCentralBank)
          );
        break;
      case "Reviewer":
        agent.PortFolios.submitForApproval(this.$route.query.id as string)
          .then(() => Notifier.showPositive("Portfolio submitted for approval"))
          .then(() => (this.formT.status = ApplicationStatus.Reviewed));
        break;

      default:
        this.$router.push({
          path: "/approve-portfolio",
          query: {
            id: this.$route.query.id,
            reference: this.formT.reference,
            businessName: this.formT.name,
          },
        });
        break;
    }
  }
  mounted() {
    agent.PortFolios.formT(this.$route.query.id as string).then((formT) => {
      this.formT = formT;
      this.loading = false;
    });
    agent.PortFolios.getApprovalLetter(this.$route.query.id as string).then(
      (document) => {
        this.approvalLetter = document;
      }
    );
  }
}
